import { addMedia } from "../utils/media-utils";
import { ObjectContentOrigins } from "../object-types";
import {
  getObjectNameGA,
  processShowIframe,
  processShowVideoEmbed,
  processPurchaseNFTProduct,
  processShowArchiveVideo
} from "../utils/livexr-utils";
import registerEvent from "../telemetry-event";

// WARNING: This system mutates interaction system state!
export class SuperSpawnerSystem {
  maybeSpawn(state, grabPath) {
    const userinput = AFRAME.scenes[0].systems.userinput;
    const superSpawner = state.hovered && state.hovered.components["super-spawner"];

    const isPermitted =
      superSpawner &&
      (superSpawner.el.object3D.name.includes("iFrame") ||
        superSpawner.el.object3D.name.includes("NFTProduct") ||
        superSpawner.el.object3D.name.includes("VimeoEmbed") ||
        superSpawner.el.object3D.name.includes("VideoEmbed"))
        ? true
        : window.APP.hubChannel &&
          (superSpawner && superSpawner.data.template === "#interactable-emoji"
            ? window.APP.hubChannel.can("spawn_emoji")
            : window.APP.hubChannel.can("spawn_and_move_media"));

    if (
      superSpawner &&
      superSpawner.spawnedMediaScale &&
      !superSpawner.cooldownTimeout &&
      userinput.get(grabPath) &&
      isPermitted
    ) {
      const objOriginName = superSpawner.el.object3D.name;
      const objectName = getObjectNameGA(objOriginName);
      const GAName = window.APP.hub.name + "_" + objectName;
      if (superSpawner.el.object3D.name.includes("iFrame")) {
        const returnData = processShowIframe(objOriginName);
        if (returnData) {
          registerEvent("iFrame_Modal", GAName, returnData.src);
        }
      } else if (superSpawner.el.object3D.name.includes("NFTProduct")) {
        const returnData = processPurchaseNFTProduct(objOriginName);
        if (returnData) {
          registerEvent("NFTProduct", GAName, returnData.src);
        }
      } else if (superSpawner.el.object3D.name.includes("ArchiveVideo")) {
        const returnData = processShowArchiveVideo(objOriginName);
        if (returnData) {
          registerEvent("Archive_Video", GAName, returnData.src);
        }
      } else if (superSpawner.el.object3D.name.includes("VideoEmbed")) {
        const returnData = processShowVideoEmbed(objOriginName);
      } else {
        this.performSpawn(state, grabPath, userinput, superSpawner);
      }
    }
  }

  performSpawn(state, grabPath, userinput, superSpawner) {
    const data = superSpawner.data;

    const spawnedEntity = addMedia(
      data.src,
      data.template,
      ObjectContentOrigins.SPAWNER,
      null,
      data.resolve,
      true,
      false,
      data.mediaOptions
    ).entity;

    // TODO HACK objects become held mid frame here, so if something runs between here and
    // when the networked component is initialized isMine will throw since data doesnt exist
    // should probably fix isMine in networked aframe instead of merging this
    if (spawnedEntity.components.networked && !spawnedEntity.components.networked.data) {
      spawnedEntity.components.networked.data = {};
    }

    superSpawner.el.object3D.getWorldPosition(spawnedEntity.object3D.position);
    superSpawner.el.object3D.getWorldQuaternion(spawnedEntity.object3D.quaternion);
    spawnedEntity.object3D.matrixNeedsUpdate = true;

    superSpawner.el.emit("spawned-entity-created", { target: spawnedEntity });

    state.held = spawnedEntity;

    superSpawner.activateCooldown();
    state.spawning = true;

    spawnedEntity.addEventListener(
      "media-loaded",
      () => {
        spawnedEntity.object3D.scale.copy(superSpawner.spawnedMediaScale);
        spawnedEntity.object3D.matrixNeedsUpdate = true;
        state.spawning = false;
        superSpawner.el.emit("spawned-entity-loaded", { target: spawnedEntity });
      },
      { once: true }
    );
  }

  tick() {
    const interaction = AFRAME.scenes[0].systems.interaction;
    if (!interaction.ready) return; //DOMContentReady workaround
    this.maybeSpawn(interaction.state.leftHand, interaction.options.leftHand.grabPath);
    this.maybeSpawn(interaction.state.rightHand, interaction.options.rightHand.grabPath);
    this.maybeSpawn(interaction.state.rightRemote, interaction.options.rightRemote.grabPath);
    this.maybeSpawn(interaction.state.leftRemote, interaction.options.leftRemote.grabPath);
  }
}
